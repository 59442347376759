











































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';

export default {
    name: 'Table',
    props: {
        context: null as ContentElementContext
    },
    computed: {
        rows()
        {
            return this.context.data.get('rows', []);
        },
        header()
        {
            return this.context.data.get('header', null);
        }
    }
};
